exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-aicoursefind-js": () => import("./../../../src/pages/aicoursefind.js" /* webpackChunkName: "component---src-pages-aicoursefind-js" */),
  "component---src-pages-be-a-partner-js": () => import("./../../../src/pages/be_a_partner.js" /* webpackChunkName: "component---src-pages-be-a-partner-js" */),
  "component---src-pages-blogs-jsx": () => import("./../../../src/pages/blogs.jsx" /* webpackChunkName: "component---src-pages-blogs-jsx" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-courses-[id]-js": () => import("./../../../src/pages/courses/[id].js" /* webpackChunkName: "component---src-pages-courses-[id]-js" */),
  "component---src-pages-drawer-jsx": () => import("./../../../src/pages/drawer.jsx" /* webpackChunkName: "component---src-pages-drawer-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-[id]-js": () => import("./../../../src/pages/news/[id].js" /* webpackChunkName: "component---src-pages-news-[id]-js" */),
  "component---src-pages-our-journey-js": () => import("./../../../src/pages/our_journey.js" /* webpackChunkName: "component---src-pages-our-journey-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-condition-js": () => import("./../../../src/pages/terms_and_condition.js" /* webpackChunkName: "component---src-pages-terms-and-condition-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-universities-[id]-js": () => import("./../../../src/pages/universities/[id].js" /* webpackChunkName: "component---src-pages-universities-[id]-js" */),
  "component---src-pages-universities-index-js": () => import("./../../../src/pages/universities/index.js" /* webpackChunkName: "component---src-pages-universities-index-js" */),
  "component---src-pages-use-footer-visibility-js": () => import("./../../../src/pages/useFooterVisibility.js" /* webpackChunkName: "component---src-pages-use-footer-visibility-js" */),
  "component---src-pages-with-footer-visibility-js": () => import("./../../../src/pages/withFooterVisibility.js" /* webpackChunkName: "component---src-pages-with-footer-visibility-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/Templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-job-application-js": () => import("./../../../src/Templates/JobApplication.js" /* webpackChunkName: "component---src-templates-job-application-js" */)
}

