import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./src/assets/css/common/typography.css";
import "./src/assets/css/common/global.css";
import AOS from "aos";
import "aos/dist/aos.css";

export const onInitialClientRender = () => {
  AOS.init();
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.action === "POP") {
    return false; // Prevents Gatsby from scrolling to the top
  }
  return true;
};
